import { Environment } from "./types";
import { defaultLangKey } from "@constants/languages";

const getEnvVars = () => {
  if (window['__env__']) {
    return window['__env__'];
  }
  return {};
};

const {
  APP_ID,
  API_URL,
  APP_DESCRIPTION,
  APP_CLIENT_DEFAULT_TRANSLATION_KEY,
  ENVIRONMENT,
  APP_NAME,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  TERTIARY_COLOR,
  LOCAL_FAVICON,
  FAVICON_PATH_URL,
  LOCAL_LOGO,
  LOGO_LOGIN_CARD,
  LOGO_PATH_URL,
  LOGO_PATH_URL_DARK,
  BACKGROUND_COLOR_IN_DARK_LOGO,
  DEFAULT_IMAGE_KEY_PHOTO,
  ENABLED_SS0_MICROSOFT,
  ENABLED_SS0_GOOGLE,
  ENABLED_SSO_JUMPCLOUD,
  ENABLED_SSO_ONELOGIN,
  ENABLED_SSO_OKTA,
  GOOGLE_MAPS_API_KEY,
  GOOGLE_ANALYTICS_API_KEY,
  ERROR_TRACKING_TOOL_KEY,
  ERROR_TRACKING_TOOL_ENABLED,
  ERROR_TRACKING_TOOL_ENVIRONMENT,
  TABLEAU_DASHBOARD_URL,
  MICROSOFT_CLARITY_PROJECT_ID,
  VERSION,
  DOCUMENT_ENGINE_URL,
  GOOGLE_SHEET_ID
} = getEnvVars();

export const configuration: Environment = {
  APP_ID: APP_ID,
  API_URL: API_URL,
  APP_DESCRIPTION: APP_DESCRIPTION,
  APP_CLIENT_DEFAULT_TRANSLATION_KEY: APP_CLIENT_DEFAULT_TRANSLATION_KEY,
  ENVIRONMENT: ENVIRONMENT,
  APP_NAME: APP_NAME,
  PRIMARY_COLOR: PRIMARY_COLOR,
  SECONDARY_COLOR: SECONDARY_COLOR,
  TERTIARY_COLOR: TERTIARY_COLOR,
  LOCAL_FAVICON: LOCAL_FAVICON,
  FAVICON_PATH_URL: FAVICON_PATH_URL,
  LOGO_LOGIN_CARD: LOGO_LOGIN_CARD,
  LOCAL_LOGO: LOCAL_LOGO,
  LOGO_PATH_URL: LOGO_PATH_URL,
  LOGO_PATH_URL_DARK: LOGO_PATH_URL_DARK,
  BACKGROUND_COLOR_IN_DARK_LOGO: BACKGROUND_COLOR_IN_DARK_LOGO,
  DEFAULT_IMAGE_KEY_PHOTO,
  ENABLED_SS0_MICROSOFT: ENABLED_SS0_MICROSOFT,
  ENABLED_SS0_GOOGLE: ENABLED_SS0_GOOGLE,
  ENABLED_SSO_JUMPCLOUD: ENABLED_SSO_JUMPCLOUD,
  ENABLED_SSO_ONELOGIN: ENABLED_SSO_ONELOGIN,
  ENABLED_SSO_OKTA: ENABLED_SSO_OKTA,
  GOOGLE_MAPS_API_KEY: GOOGLE_MAPS_API_KEY,
  GOOGLE_ANALYTICS_API_KEY: GOOGLE_ANALYTICS_API_KEY,
  ERROR_TRACKING_TOOL_ENABLED: ERROR_TRACKING_TOOL_ENABLED,
  ERROR_TRACKING_TOOL_KEY: ERROR_TRACKING_TOOL_KEY,
  ERROR_TRACKING_TOOL_ENVIRONMENT: ERROR_TRACKING_TOOL_ENVIRONMENT,
  TABLEAU_DASHBOARD_URL: TABLEAU_DASHBOARD_URL,
  MICROSOFT_CLARITY_PROJECT_ID: MICROSOFT_CLARITY_PROJECT_ID,
  VERSION: VERSION,
  DOCUMENT_ENGINE_URL: DOCUMENT_ENGINE_URL,
  GOOGLE_SHEET_ID: GOOGLE_SHEET_ID
};

export const isDevelopment = (): boolean => {
  const environment = (window["__env__"]?.ENVIRONMENT);
  return Boolean(environment && environment?.toLowerCase?.().includes("development"));
}

export const isStaging = (): boolean => {
  const environment = (window["__env__"]?.ENVIRONMENT);
  return Boolean(environment && environment?.toLowerCase?.().includes("staging"));
}

export const isEnabledSsoMicrosoft = (): boolean => {
  const { ENABLED_SS0_MICROSOFT } = configuration;
  return Boolean(ENABLED_SS0_MICROSOFT === "true")
}

export const isEnabledSsoGoogle = (): boolean => {
  const { ENABLED_SS0_GOOGLE } = configuration;
  return Boolean(ENABLED_SS0_GOOGLE === "true")
}

export const isEnabledSsoJumpcloud = (): boolean => {
  const { ENABLED_SSO_JUMPCLOUD } = configuration;
  return Boolean(ENABLED_SSO_JUMPCLOUD === "true")
}

export const isEnabledSsoOnelogin = (): boolean => {
  const { ENABLED_SSO_ONELOGIN } = configuration;
  return Boolean(ENABLED_SSO_ONELOGIN === "true")
}

export const isEnabledSsoOkta = (): boolean => {
  const { ENABLED_SSO_OKTA } = configuration;
  return Boolean(ENABLED_SSO_OKTA === 'true');
};

export const isEnabledErrorTrackerTool = (): boolean => {
  const { ERROR_TRACKING_TOOL_ENABLED } = configuration;
  return Boolean(ERROR_TRACKING_TOOL_ENABLED === "true")
}

export const getDefaultLangOfClient = (): string => {
  try {
    return configuration?.APP_CLIENT_DEFAULT_TRANSLATION_KEY?.split!("-")[0];
  } catch (error) {
    return defaultLangKey;
  }
}

export const getIdiomClient = (): string => {
  try {
    return configuration?.APP_CLIENT_DEFAULT_TRANSLATION_KEY?.split!("-")[1];
  } catch (error) {
    return "";
  }
}
