/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useLayoutEffect, useState } from "react";
import { useRef } from 'react';
import { getLib, getRevealApiUrl, loadReavealGlobally } from './LoaderRevealLibrary';
import { useRevealContainer } from './UseRevealContainer';
import { useRevealContextProvider } from './RevealProvider';
import { Skeleton } from 'antd';
import { If } from '@components/Utils/Structural';
import { useLayoutContex } from '@providers/LayoutProvider';
import { configuration } from '@environments/env';

interface IRevealContainerProps {
  dashboardName: string;
}

export const RevealContainer = (props: IRevealContainerProps) => {
  const { dashboardName } = props;
  const { canEditDashboard } = useRevealContextProvider();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    startLoad();
  }, []);

  // make a useRef to the revealView
  const revealViewRef = useRef<any>();

  const { themeConfig, setDarkMode, setLightMode } = useRevealContainer(revealViewRef);

  const assignRevealView = () => {
    const instance = getLib();
    if (instance) {
      revealViewRef.current = new instance.RevealView('#revealView');
    }
  };

  const callOnDataSourcesRequested = () => {
    const instance = getLib();
    if (instance) {
      // CallBack onDataSourcesRequested
      revealViewRef.current.onDataSourcesRequested = async callback => {
        // Conection to the Reveal DataSources
        var ds = new instance.RVPostgresDataSource();
        ds.id = 'postgres';
        ds.title = 'SiteRise DB';
        ds.subtitle = '';

        const fe = [];
        if (configuration.GOOGLE_SHEET_ID) {
          // Google sheets
          const sheetId = configuration.GOOGLE_SHEET_ID;
          const googleDriveDSI = new instance.RVGoogleDriveDataSourceItem();
          googleDriveDSI.identifier = sheetId;

          // Indicate the file is a google sheet and set the sheet name
          const googleSheet  = new instance.RVGoogleSheetDataSourceItem(googleDriveDSI);
          googleDriveDSI.title = "SiteRise Google Sheet";
          googleSheet.subtitle = "Google Drive";
          googleSheet.sheet = "Sheet1";
          // @ts-ignore
          fe.push(googleSheet)
        }

        callback(new instance.RevealDataSources([ds], fe, false));
      };
    }
  };

  const loadDashboard = () => {
    const instance = getLib();
    if (instance && instance?.RevealSdkSettings) {
      themeConfig?.darkMode ? setDarkMode() : setLightMode();

      instance.RevealSdkSettings.setBaseUrl(getRevealApiUrl());

      // Set the credentials flag to API requests
      instance.RevealSdkSettings.requestWithCredentialsFlag = true;

      //load dashboard from server
      instance.RVDashboard.loadDashboard(dashboardName)
        .then(dashboard => {
          // 1 - Assign the revealView to the ref
          assignRevealView?.();

          // 2 - Set the dashboard if it was loaded
          revealViewRef.current.dashboard = dashboard;

          // Settings
          revealViewRef.current.canEdit = Boolean(canEditDashboard());
          revealViewRef.current.canSaveAs = false;

          revealViewRef.current.interactiveFilteringEnabled = true;

          // Call on DataSourcesRequested
          callOnDataSourcesRequested?.();
        })
        .catch(() => {
          // Assign the revealView to the ref
          assignRevealView?.();

          // Call on DataSourcesRequested
          callOnDataSourcesRequested?.();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const startLoad = () => {
    try {
      loadReavealGlobally({
        onLoadLibraryCallback: () => loadDashboard(),
        onErrorLibraryCallback: () => setIsLoading(false),
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const { collapseNav } = useLayoutContex();

  // Update the size of the revealView once the collapseNav changes
  useLayoutEffect(() => {
    if (revealViewRef.current) {
      setTimeout(() => {
        revealViewRef.current.updateSize?.();
      }, 600);
    }
  }, [collapseNav]);

  return (
    <>
      <If condition={Boolean(isLoading)}>
        <Skeleton loading={true} paragraph={{ rows: 25 }} active={true} className="pl-20 pr-20 pt-20 pb-20"></Skeleton>
      </If>
      <div data-testid="containerDashboard" className="w-full h-full">
        <div id="revealView" className="w-full h-full relative"></div>
      </div>
    </>
  );
};
